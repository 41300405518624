<template>
	<el-dialog title="查看详情" :visible.sync="detailVisible" :close-on-click-modal="false" width="1080px" :before-close="handleClose">
		<el-tabs tab-position="left" v-model="activeName">
			<el-tab-pane label="基本信息" name="1">
				<transition>
					<BaseInfo v-if="activeName === '1'" :baseInfo="bedInfo" />
				</transition>
			</el-tab-pane>
			<el-tab-pane label="建设日志" name="2">
				<transition>
					<BuildLog v-if="activeName === '2'" :bedInfo="bedInfo" />
				</transition>
			</el-tab-pane>
			<!-- <el-tab-pane label="建设跟踪" name="3" v-if="bedInfo.orderHksId">
				<transition>
					<BuildTrack v-if="activeName === '3'" :orderId="bedInfo.orderHksId" />
				</transition>
			</el-tab-pane> -->
			<el-tab-pane label="建设清单" name="4">
				<transition>
					<BuildList v-if="activeName === '4'" :bedId="bedInfo.id" />
				</transition>
			</el-tab-pane>
		</el-tabs>
		<div slot="footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleClose">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import BaseInfo from './detailComponents/baseInfo.vue'
import BuildLog from './detailComponents/buildLog.vue'
import BuildTrack from './detailComponents/buildTrack.vue'
import BuildList from './detailComponents/buildList.vue'
export default {
	// 床位详情弹窗
	name: 'bedDetail',
	components: {
		BaseInfo,
		BuildLog,
		BuildTrack,
		BuildList,
	},
	props: {
		bedInfo: {
			type: Object,
			default: () => {
				return {}
			},
		},
		detailVisible: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {},
	data() {
		return {
			activeName: '1',
			tableData: [],
		}
	},
	methods: {
		handleClose() {
			this.$emit('update:detailVisible', false)
		},
	},
}
</script>

<style lang="scss" scoped>
.bedDetail {
	::v-deep .el-form-item__content {
		font-size: 0.083333rem /* 16/192 */;
	}
}
</style>
